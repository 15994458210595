import {
  onChildAdded,
  onChildChanged,
  onValue,
  ref,
  update,
} from "firebase/database";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../config/firebase";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getStationByDwelling, getStationData } from "../api";
import {
  setDepartmentPresences,
  setIsOnline,
  setOnlineDepartmentsRedux,
  setOnlinePeople,
  setServerTime,
  setStationData,
} from "../redux/reducers/stationDataReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { setCallData } from "../redux/reducers/callReducer";
import { setDwellingData } from "../redux/reducers/dwellingReducer";
import i18n from "../config/i18n";
import { setCurrentLanguage } from "../redux/reducers/languageReducer";
import moment from "moment-timezone";

export default function StationWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const stationData = useAppSelector((state) => state.data.stationData);
  const serverTime = useAppSelector((state) => state.data.serverTime);
  const currentLanguage = useAppSelector(
    (state) => state.language.currentLanguage
  );

  const [stationInterval, setStationInterval] = useState<any>(null);
  const [stationPresenceInterval, setStationPresenceInterval] =
    useState<any>(null);
  const [people, setPeople] = useState<any>([]);

  const savedServerTime = useRef<any>(null);

  const handleVideoCallBackRef = useCallback(
    (dataServer: any) => {
      const callsQuery = ref(
        db,
        `calls/${dataServer.Auth0Id ? dataServer.Auth0Id : dataServer.OwnerId}`
      );

      onChildChanged(callsQuery, async (data) => {
        if (data.val().status === 5) {
          if (data.val().stationCode === dataServer.StationCode) {
            localStorage.setItem("callBackLink", data.val().acsLink);
            const callData = {
              callType: 5,
              callTypeName: data.val().callTypeName,
              currentCall: data.key,
              stayId: data.val().stayId,
              regularUserIds: [],
              microsoftCallerIds: [],
            };
            const serverTerms = await getStationByDwelling(
              dataServer.DwellingId
            );

            if (serverTerms) {
              dispatch(setCallData(callData));
              dispatch(setDwellingData(serverTerms.data));
              navigate("/call");
            }
          }
        }
      });

      onChildAdded(callsQuery, async (data) => {
        if (data.val().status === 6) {
          if (data.val().stationCode === dataServer.StationCode) {
            localStorage.setItem("callBackLink", data.val().acsLink);
            const callData = {
              callType: 6,
              callTypeName: data.val().callTypeName,
              currentCall: data.key,
              stayId: data.val().stayId,
              regularUserIds: [],
              microsoftCallerIds: [],
            };
            const serverTerms = await getStationByDwelling(
              dataServer.DwellingId
            );

            if (serverTerms) {
              dispatch(setCallData(callData));
              dispatch(setDwellingData(serverTerms.data));
              navigate("/call");
            }
          }
        }
      });
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (stationData && people && people.length > 0 && serverTime) {
      const peopleArray = [];

      for (let i = 0; i < people.length; i++) {
        if (people[i][0] !== "stations") {
          peopleArray.push(people[i][1]);
        }
      }

      const availablePeople = peopleArray.filter(
        (e: any) => e.lastSeen > serverTime - 360000 && !e.offlineSelected
      );

      const availableDepartmentsRaw = new Set();

      for (let i = 0; i < availablePeople.length; i++) {
        if (availablePeople[i].selectedDepartments) {
          if (availablePeople[i].selectedDepartments.includes("|")) {
            const departments =
              availablePeople[i].selectedDepartments.split("|");
            for (let x = 0; x < departments.length; x++) {
              availableDepartmentsRaw.add(departments[x]);
            }
          } else {
            availableDepartmentsRaw.add(availablePeople[i].selectedDepartments);
          }
        } else {
          availableDepartmentsRaw.add("0");
        }
      }

      const availableDepartments = Array.from(availableDepartmentsRaw).filter(
        (e) => e
      );

      dispatch(setOnlineDepartmentsRedux(availableDepartments));
      dispatch(setOnlinePeople(availablePeople.map((e: any) => e.id)));
    }
  }, [stationData, people, serverTime, dispatch]);

  const handleAvailabiltyCheck = useCallback(
    (userArr: any, data: any, timeServer: number) => {
      const newStationButtons = [...data.stationButton];
      const updatedStationButtons = [];

      for (const e of newStationButtons) {
        const updatedObj = Object.assign({}, e, {
          isOnline: false,
          fcmTokens: e.fcmTokens ? [...e.fcmTokens] : [],
          smsArr: e.smsArr ? [...e.smsArr] : [],
        });

        updatedStationButtons.push(updatedObj);
      }

      for (let x = 0; x < userArr.length; x++) {
        const user = userArr[x][1];
        if (userArr[x][0] !== "stations") {
          for (let i = 0; i < updatedStationButtons.length; i++) {
            if (!updatedStationButtons[i].hasOwnProperty("isOnline")) {
              updatedStationButtons[i].isOnline = false;
            }
            if (updatedStationButtons[i].Enable) {
              if (!!user.selectedDwellings) {
                let selectedArray = user.selectedDwellings.split("|");
                if (!selectedArray.includes(data.DwellingId.toString())) {
                  continue;
                }
              }
              if (
                updatedStationButtons[i].SelectedDepartments &&
                updatedStationButtons[i].SelectedDepartments.length > 0
              ) {
                if (!!user.selectedDepartments) {
                  if (
                    updatedStationButtons[i].SelectedDepartments.includes("|")
                  ) {
                    const buttonDeptArray =
                      updatedStationButtons[i].SelectedDepartments.split("|");
                    const intersection = buttonDeptArray.filter(
                      (element: any) =>
                        user.selectedDepartments.includes(element)
                    );
                    const enable = intersection.length > 0 ? true : false;

                    if (enable) {
                      if (!(userArr[x][1].lastSeen < timeServer - 360000)) {
                        if (!updatedStationButtons[i].isOnline) {
                          updatedStationButtons[i].isOnline =
                            !user.offlineSelected;
                        }
                      }
                      if (!!user.fcmToken && !user.offlineSelected) {
                        if (
                          !updatedStationButtons[i].fcmTokens.some(
                            (e: any) => e === user.fcmToken
                          )
                        )
                          updatedStationButtons[i].fcmTokens.push(
                            user.fcmToken
                          );
                      }
                      if (!!user.sms && !user.offlineSelected) {
                        if (
                          !updatedStationButtons[i].smsArr.some(
                            (e: any) => e === user.sms
                          )
                        )
                          updatedStationButtons[i].smsArr.push(user.sms);
                      }
                    }
                  } else {
                    const enable = user.selectedDepartments.includes(
                      updatedStationButtons[i].SelectedDepartments
                    );
                    if (enable) {
                      if (!(userArr[x][1].lastSeen < timeServer - 360000)) {
                        if (!updatedStationButtons[i].isOnline) {
                          updatedStationButtons[i].isOnline =
                            !user.offlineSelected;
                        }
                      }
                      if (!!user.fcmToken && !user.offlineSelected)
                        if (
                          !updatedStationButtons[i].fcmTokens.some(
                            (e: any) => e === user.fcmToken
                          )
                        )
                          updatedStationButtons[i].fcmTokens.push(
                            user.fcmToken
                          );
                      if (!!user.sms && !user.offlineSelected)
                        if (
                          !updatedStationButtons[i].smsArr.some(
                            (e: any) => e === user.sms
                          )
                        )
                          updatedStationButtons[i].smsArr.push(user.sms);
                    }
                  }
                }
              } else {
                if (
                  !user.selectedDepartments &&
                  !updatedStationButtons[i].SelectedEmployees
                ) {
                  updatedStationButtons[i].FallBackCall = data.FallBackCall;
                  if (!(userArr[x][1].lastSeen < timeServer - 360000))
                    if (!updatedStationButtons[i].isOnline)
                      updatedStationButtons[i].isOnline = !user.offlineSelected;
                  if (!!user.fcmToken && !user.offlineSelected)
                    if (
                      !updatedStationButtons[i].fcmTokens.some(
                        (e: any) => e === user.fcmToken
                      )
                    )
                      updatedStationButtons[i].fcmTokens.push(user.fcmToken);
                  if (!!user.sms && !user.offlineSelected)
                    if (
                      !updatedStationButtons[i].smsArr.some(
                        (e: any) => e === user.sms
                      )
                    )
                      updatedStationButtons[i].smsArr.push(user.sms);
                }
              }
            }
          }
        }
      }

      for (let i = 0; i < updatedStationButtons.length; i++) {
        if (updatedStationButtons[i].MicrosoftCallerIds) {
          if (updatedStationButtons[i].MicrosoftCallerIds.length > 0) {
            updatedStationButtons[i].isOnline = true;
          }
        }

        if (
          updatedStationButtons[i].SelectedSignInFlow &&
          updatedStationButtons[i].SelectedSignInFlow.length > 0 &&
          updatedStationButtons[i].Attachment
        ) {
          updatedStationButtons[i].isOnline = true;
        }

        if (
          updatedStationButtons[i].SelectedSignOutFlow &&
          updatedStationButtons[i].SelectedSignOutFlow.length > 0 &&
          updatedStationButtons[i].Attachment
        ) {
          updatedStationButtons[i].isOnline = true;
        }

        if (
          updatedStationButtons[i].SelectedAttachment &&
          updatedStationButtons[i].SelectedAttachment.length > 0 &&
          updatedStationButtons[i].Attachment
        ) {
          updatedStationButtons[i].isOnline = true;
        }

        if (
          updatedStationButtons[i].SelectedEmployees &&
          updatedStationButtons[i].SelectedEmployees.length > 0
        ) {
          const peopleArray = [];

          for (let i = 0; i < userArr.length; i++) {
            if (userArr[i][0] !== "stations") {
              peopleArray.push(userArr[i][1]);
            }
          }

          const availablePeopleIds = peopleArray
            .filter(
              (e: any) => e.lastSeen > serverTime - 360000 && !e.offlineSelected
            )
            .map((e: any) => e.id)
            .filter((e: any) => e);

          const allButtons: any[] = updatedStationButtons;
          const selectedEmployee = data.employees.find(
            (e: any) => e.id === parseInt(allButtons[i].SelectedEmployees)
          );

          if (
            selectedEmployee &&
            selectedEmployee.MicrosoftEnabled &&
            selectedEmployee.MicrosoftUserId
          ) {
            updatedStationButtons[i].isOnline = true;
            updatedStationButtons[i].MicrosoftCallerIds = [
              {
                id: selectedEmployee.MicrosoftUserId,
                isCallQueue: selectedEmployee.IsCallQueue,
              },
            ];
          } else if (selectedEmployee) {
            if (availablePeopleIds.includes(parseInt(selectedEmployee.id))) {
              updatedStationButtons[i].isOnline = true;
            } else {
              updatedStationButtons[i].isOnline = false;
            }
          }
        }

        if (
          updatedStationButtons[i].SelectedNotification &&
          updatedStationButtons[i].SelectedNotification.length > 0
        ) {
          updatedStationButtons[i].isOnline = true;
        }
      }

      const online = updatedStationButtons.some((e) => e.isOnline === true);

      if (!online) {
        return false;
      }

      dispatch(setDepartmentPresences(updatedStationButtons));

      return true;
    },
    [dispatch]
  );

  const handlePresence = useCallback(
    (dataServer: any) => {
      const queryStations = ref(
        db,
        `presences/${
          dataServer.Auth0Id ? dataServer.Auth0Id : dataServer.OwnerId
        }`
      );

      onValue(queryStations, async (data: any) => {
        let isOnline = false;
        const dataArray = Object.entries(data.val());

        setPeople(dataArray);

        if (!savedServerTime.current) {
          savedServerTime.current = moment(new Date().getTime())
            .tz("America/New_York")
            .valueOf();
        }

        isOnline = handleAvailabiltyCheck(
          dataArray,
          dataServer,
          savedServerTime.current
        );

        let havingCall = false;
        if (!isOnline) {
          for (var i = 0; i < dataServer.stationButton.length; i++) {
            if (!!dataServer.stationButton[i].FallBackCall) {
              havingCall = true;
            }
          }

          const adminPhoneNumber = dataServer.FallBackCall;
          if (adminPhoneNumber && adminPhoneNumber.length > 0) {
            const newButtons = [];

            for (let i = 0; i < dataServer.stationButton.length; i++) {
              const currentObj = Object.assign(
                {},
                dataServer.stationButton[i],
                {
                  isOnline: false,
                  fcmTokens: dataServer.stationButton[i].fcmTokens
                    ? [...dataServer.stationButton[i].fcmTokens]
                    : [],
                  smsArr: dataServer.stationButton[i].smsArr
                    ? [...dataServer.stationButton[i].smsArr]
                    : [],
                }
              );
              if (
                !dataServer.stationButton[i].SelectedDepartments ||
                dataServer.stationButton[i].SelectedDepartments.length === 0
              ) {
                currentObj.FallBackCall = adminPhoneNumber;
              }
              newButtons.push(currentObj);
            }
            dispatch(setDepartmentPresences([...newButtons]));
          } else {
            dispatch(setDepartmentPresences([...dataServer.stationButton]));
          }
        }

        dispatch(setServerTime(savedServerTime.current));
        dispatch(setIsOnline(isOnline || havingCall));

        if (
          !isOnline &&
          !havingCall &&
          !dataServer.FallBackCall &&
          window.location.href.slice(-7) !== "offline"
        ) {
          navigate("/offline");
        }
      });
    },
    [dispatch, handleAvailabiltyCheck, location.pathname, navigate]
  );

  const handleGetStationData = useCallback(async () => {
    if (!!stationData) {
      handlePresence(stationData);
      handleVideoCallBackRef(stationData);
    } else {
      const code = localStorage.getItem("stationCode");
      if (!!code) {
        const serverStationData = await getStationData(code);

        if (serverStationData && serverStationData.status === 1) {
          const dwelling = await getStationByDwelling(
            serverStationData.data.DwellingId
          );

          dispatch(setDwellingData(dwelling.data));
          dispatch(setStationData(serverStationData.data));
          handlePresence(serverStationData.data);
          handleVideoCallBackRef(serverStationData.data);
        } else {
          if (
            serverStationData &&
            serverStationData.message === "Station not found" &&
            process.env.REACT_APP_IS_MAINTENANCE !== "true"
          ) {
            alert("Station not found");
            localStorage.removeItem("stationCode");
            window.location.reload();
          }
        }
      } else {
        navigate("/");
      }
    }
  }, [dispatch, handlePresence, handleVideoCallBackRef, navigate, stationData]);

  useEffect(() => {
    const asyncCheck = async () => {
      await handleGetStationData();
    };

    asyncCheck();
  }, [handleGetStationData]);

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    } else if (stationData && stationData.DefaultLang) {
      i18n.changeLanguage(stationData.DefaultLang);
      dispatch(setCurrentLanguage(stationData.DefaultLang));
    }
  }, [currentLanguage, dispatch, stationData]);

  useEffect(() => {
    const asyncCheck = async () => {
      if (stationData && !stationInterval && !stationPresenceInterval) {
        setStationInterval(
          setInterval(() => {
            if (
              !window.location.href.includes("call") &&
              !window.location.href.includes("visitorsignin")
            ) {
              window.location.href = "/";
              navigate("/");
              window.location.reload();
            }
          }, 300000)
        );

        const presenceQuery = ref(
          db,
          `presences/${
            stationData.Auth0Id ? stationData.Auth0Id : stationData.OwnerId
          }/stations/${stationData.StationCode}`
        );

        setStationPresenceInterval(
          setInterval(async () => {
            if (!stationData) return;

            update(presenceQuery, {
              lastSeen: new Date().getTime(),
              name: stationData.StationName,
              dwellingName: stationData.DwellingName,
              isSentEmail: true,
              code: stationData.StationCode,
              dwellingId: stationData.DwellingId.toString(),
              authId: stationData.Auth0Id
                ? stationData.Auth0Id
                : stationData.OwnerId,
              ownerId: stationData.OwnerId,
            });
          }, 30000)
        );

        return () => {
          clearInterval(stationInterval);
          clearInterval(stationPresenceInterval);
        };
      }
    };

    asyncCheck();
  }, [navigate, stationData, stationInterval, stationPresenceInterval]);

  return <>{children}</>;
}

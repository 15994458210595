import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "../../../hooks";
import PhotoSound from "../../../assets/sounds/photoSound.mp3";

interface SelfieTakeProps {
  buttonId: number;
  setCurrentPage: (page: number) => void;
  selfiePictureImageData: string;
  setSelfiePictureImageData: (imageData: string) => void;
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export default function SelfieTake({
  buttonId,
  setCurrentPage,
  selfiePictureImageData,
  setSelfiePictureImageData,
}: SelfieTakeProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const webcamRef = useRef<any>(null);

  useEffect(() => {
    if (buttonId && stationData) {
      const isMultipleAttachments =
        query.get("isMultipleAttachments") === "true";

      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === buttonId
      );

      if (!selectedButton) {
        navigate("/options");
      }

      let isTakingSelfie = false;

      if (isMultipleAttachments) {
        const selectedAttachment = selectedButton.Attachment.find(
          (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
        );

        if (selectedAttachment) {
          isTakingSelfie = selectedAttachment.IsTakingSelfie;
        }
      } else {
        isTakingSelfie = selectedButton.Attachment.IsTakingSelfie;
      }

      if (!isTakingSelfie) {
        setCurrentPage(3);
      }
    } else {
      navigate("/options");
    }
  }, [buttonId, navigate, setCurrentPage, stationData]);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const availableCameras = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (availableCameras.length === 0) {
          toast.error("No camera found", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error("No camera found", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  const handleNextPage = () => {
    let isAllFilled = true;

    if (selfiePictureImageData.trim().length === 0) {
      isAllFilled = false;
    }

    if (!isAllFilled) {
      toast.error("Please take a selfie", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setCurrentPage(3);
  };

  const capture = useCallback(() => {
    if (selfiePictureImageData) {
      setSelfiePictureImageData("");
      return;
    }

    const audio = document.getElementById("audio");
    if (audio) (audio as HTMLAudioElement).play();

    const imageSrc = webcamRef.current!.getScreenshot();

    setSelfiePictureImageData(imageSrc);
  }, [selfiePictureImageData, setSelfiePictureImageData]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center min-h-[90vh] pt-16">
      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            setCurrentPage(1);
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>

      <div className="relative w-full h-full flex flex-col justify-center items-center mt-10 z-2">
        {selfiePictureImageData ? (
          <img
            src={selfiePictureImageData}
            alt="selfie"
            className="max-w-[70%] max-h-[70vh]"
          />
        ) : (
          <div className="w-1/2 h-full mt-10">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              style={{
                borderRadius: "15px",
              }}
            />
          </div>
        )}
        <button
          className="bg-primary flex justify-center items-center mt-6 w-56 py-3 rounded-lg text-white text-2xl hover:bg-green-200"
          onClick={capture}
        >
          <FontAwesomeIcon icon={faCamera} className="mr-2 text-white" />
          {selfiePictureImageData ? t("retakeSelfie") : t("takeSelfie")}
        </button>

        <audio id="audio" src={PhotoSound}></audio>

        <button
          className="mt-16 bg-primary w-56 py-3 text-white rounded-lg text-2xl hover:bg-green-200"
          onClick={handleNextPage}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
}

import React, { useCallback, useState } from "react";
import { Stack } from "@fluentui/react";
import {
  ControlBar,
  EndCallButton,
  VideoGallery,
  VideoStreamOptions,
  usePropsFor,
} from "@azure/communication-react";

export default function StationVideo({
  handleHangOut,
  showIdOverlay,
  call,
}: any) {
  const videoGalleryProps = usePropsFor(VideoGallery);
  const endCallProps = usePropsFor(EndCallButton);

  const [callEnded, setCallEnded] = useState<boolean>(false);

  const containerStyle = {
    height: "100vh",
    width: "100vw",
  };

  const onHangup = useCallback(async () => {
    call.off("remoteParticipantsUpdated", () => {});
    try {
      await endCallProps.onHangUp();
    } catch (e) {
      console.log("error", e);
    }
    handleHangOut();
    setCallEnded(true);
  }, [call, endCallProps, handleHangOut, setCallEnded]);

  if (callEnded) {
    return (
      <div className="text-center w-full">
        <h1 className="text-3xl font-bold">Call Ended</h1>
      </div>
    );
  }

  const remoteVideoViewOptions = {
    scalingMode: "Crop",
    isMirrored: false,
  } as VideoStreamOptions;

  return (
    <>
      <Stack style={containerStyle}>
        {videoGalleryProps && (
          <>
            <VideoGallery
              layout={showIdOverlay ? "default" : "floatingLocalVideo"}
              localVideoViewOptions={remoteVideoViewOptions}
              remoteVideoViewOptions={remoteVideoViewOptions}
              {...videoGalleryProps}
            />
          </>
        )}
      </Stack>
      <div>
        <ControlBar
          layout="floatingBottom"
          styles={{
            root: {
              marginBottom: "1rem",
            },
          }}
        >
          {endCallProps && (
            <EndCallButton
              {...endCallProps}
              onHangUp={onHangup}
              className="px-7 py-3"
            />
          )}
        </ControlBar>
      </div>
      <h2 className="text-center font-bold text-base my-2 text-white">
        Call ID: {call.id}
      </h2>
    </>
  );
}
